"use strict"
import '../scss/main.scss';
const { fromEvent, merge} = require("rxjs");
const {isEmail} = require('validator')
const { DjangoApi } = require('./libs/django');
const {  Api } = require('./libs/api'); 
const { toggleDropdown, mobileMenuOpen, mobileMenuClose } = require('./libs/ui'); 

const {  showCountItemsBtn, checkSortParams } = require('./libs/ui'); 



fromEvent(window, 'load').subscribe(data => { // todo not refact (refactor in a last version)    
    // socketListner();
    checkSortParams();
    const api  =  new Api();

    // нажатие кнопки "искать"
    let elSearchButton  =    document.querySelector('.search__btn')
    if (elSearchButton) {
        fromEvent(elSearchButton, 'click').subscribe({
            next: (e)=>{
                api.setSearchText()                 
            },
            error: (e)=>{
                console.log(e);
            }
        });
    }
    // работа с меню 
    let elCustomInputFilter = document.getElementsByClassName('custom_text_input');
    let elMenuFilters = document.getElementsByClassName('menufilter_input');

    if (elCustomInputFilter.length && elMenuFilters.length) {                        
        merge(
            fromEvent(elCustomInputFilter, 'change'), 
            fromEvent(elMenuFilters, 'change')
        ).subscribe(
            {
                next: (e)=>{  

                let tag = e.currentTarget?.dataset?.tag;
                // console.log(tag);

                switch(tag) {
                    
                    case 'price':
                        api.addFilterPrice(e);
                    break;
                    case 'cost':
                        api.addFilter(e);
                    break;
                    case 'closed':
                        api.addFilter(e);
                    break;               
                    case 'length':                        
                        api.addFilterLength(e); 
                    break;                                   
                    case 'width':
                        api.addFilterWidth(e);
                    break;                                   
                    case 'filter':
                        api.addFilter(e);
                    break;                                                       
                }
                showCountItemsBtn(e)
            },
            error: (e)=>{
                console.log(e);
            }
        });
    }

    var elm = document.getElementsByClassName('category');
    if (elm.length) {
        var source = fromEvent(elm, 'click');
        var subscribe = source.subscribe(
            (val) => {
                api.setCategoryTypeNavigate(1);
            }
        );
    }
    var elm = document.getElementsByClassName('filter');
    if (elm.length) {
        var source = fromEvent(elm, 'click');
        var subscribe = source.subscribe(
            (val) => {
                api.setCategoryTypeNavigate(0);
            }
        );
    }

    let elmProfile =  document.querySelector('.lk');
    if (elmProfile) {

        fromEvent(elmProfile, 'click').subscribe(
            (val) => {
                toggleDropdown('lk');
            }
        );

    }
    let elmPriceList =  document.querySelector('.pricelist');
    if (elmPriceList) {
        fromEvent(elmPriceList, 'click').subscribe(
            (val) => {
                api.generatePrice();
            }
        );
    }

    let elRegionDiv = document.querySelector('.region_div')
    if (elRegionDiv) {
        fromEvent(elRegionDiv, 'click').subscribe(
            (val) => {
                   api.openModalRegion(); 
                // api.generatePrice();
            }
        );
    }

    let elRegionBtn = document.querySelector('.region_btn')
    if (elRegionBtn) {
        fromEvent(elRegionBtn, 'click').subscribe(
            (val) => {
                   api.openModalRegion(); 
                // api.generatePrice();
            }
        );
    }


    
    let elSetRegion = document.getElementsByClassName('set-region')
    if (elSetRegion) {
        fromEvent(elSetRegion, 'click')
        .subscribe((e) => {
            let id       = e.currentTarget.dataset?.id; 
            let type     = e.currentTarget.dataset?.type;        
            if (id) {
                api.setRegion(id,type)
                // DjangoApi.prototype.setRegion(id, type).subscribe((resp) => {                     
                //     document.location = resp.response.url;
                // });
            }         
        })  
    }    
    let elUserMenuImg = document.querySelector('.user_menu_trigger')?.querySelector(".icon");
    if (elUserMenuImg) {        
        fromEvent(elUserMenuImg, 'click')
        .subscribe((e) => {
            console.log('ssss');
            mobileMenuOpen(e);            
        })  

    }

    let elInfoMenuImg = document.querySelector('.info_menu_trigger')?.querySelector(".icon");
    if (elInfoMenuImg) {        
        fromEvent(elInfoMenuImg, 'click')
        .subscribe((e) => {           
            mobileMenuOpen(e);            
        })  

    }

    let elCloseModalIcon = document.querySelector('.close_icon');
    let elCloseMenuMask = document.querySelector('.menu_mask');
    if (elCloseModalIcon || elCloseMenuMask ) {        
        merge(fromEvent(elCloseModalIcon, 'click'), fromEvent(elCloseMenuMask, 'click'))        
        .subscribe((e) => {        
            mobileMenuClose();
        });  
    }


    // user_menu_trigger





});    

// function socketListner() {
    
//     DjangoApi.prototype.connectSocket(reciveMessage);

//     fromEvent(document.querySelector('#send-form-btn'), 'click').subscribe((e) => {
//         const input = document.querySelector('#msg_field');        
//         console.log(input.value);
//         DjangoApi.prototype.soketNext(input.value);
//         input.value = '';
//     });


    
    // DjangoApi.prototype.soketNext("Test");
    // fromEvent(document.querySelector('#send-form-btn'), 'click').subscribe( (e) => {    
    //     const input = document.querySelector('#msg_field');        
    //     DjangoApi.soketNext(input.value);
    //     input.value = '';
    // } );    }

// function reciveMessage(msg) {    
//     let isMine; 
//     console.log(msg);

//     let text = msg.data.text;
//     let msgFrom = msg.msg_from;
//     let msgTo = msg.msg_to;
//     let action = msg.action;
//     if (msgTo !== DjangoApi.prototype.roomId ) {
//         isMine = 'out';
//     }
//     console.log(action);
//     switch (action) {
//         case 'message':
//             console.log(text);
//             showMessage(text, isMine);            

//         break;
//         case 'manager_connect':
//             showMessage("manager connect!", isMine);            
//         break;
//         case 'manager_disconnect':
//             showMessage("manager disconnect!", isMine);            
//         break;
//     }
    
// }
// function showMessage( text, isMine = null ) {  

//     document.querySelector("#massage-list").innerHTML += `
//                     <div class="msg ${isMine ? 'out' : 'in'}">
//                         <span>${text}</span>
//                     </div>
//                 `;
// }

// window.rxjs_get = {}
// window.api = new Api()
