"use strict"
const { fromEvent, merge} = require("rxjs");
const {DjangoApi} = require('./django');
const { Modal, Dropdown } = require('bootstrap');

export class Api {
    mutantPopup = {};
    django = {};
    pageParams = {};
    
    constructor() {
        this.django = new DjangoApi();
    }
    
    /**
     * добавление фильтра      
     */
    addFilter(e) {
        const obj = e.currentTarget
        const FIELD_FILTER  =  obj.dataset?.tag;        
        let newParams = '';
        let submintElement  = document.getElementById("submit-page");
        let params          = new URLSearchParams(submintElement.getAttribute('href').split('?')[1]);
            params.delete('page');                
        let value = params.get(FIELD_FILTER)?.split(',');
        // console.log(value);
            params = this.addPrWdLn(params);        

        let id  = obj.dataset.id;            
        if (!id){
            id = 1;
        }

        // let targetContainer = el.target.closest('.item_list_input');
        // let targetContainer = el.target.closest('.target_container');
        let counterDiv      = document.querySelector('#find-count-items');        
        if (obj.checked) {
            if (value) {
                value.push(id)
                value = [...(new Set(value))];
                params.set(FIELD_FILTER, value);
                console.log(params.get(FIELD_FILTER))
            } else {
                params.set(FIELD_FILTER, id)
            }
            newParams = `?${params.toString()}`;
        } else {            
            if (value) {
                value.splice(value.indexOf(id), 1);
                params.delete(FIELD_FILTER);
                value.length ? (params.set(FIELD_FILTER, value), newParams = `?${params.toString()}`) : newParams = `?${params.toString()}`;
            }
        }
        
        let newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${newParams}`;
        submintElement.setAttribute('href', newurl);
        counterDiv.setAttribute('href', newurl);
        this.getFilterData(newurl, obj, counterDiv);
    }

    addPrWdLn(params) {        
        if (document.querySelector('.price .custom_text_input:nth-child(1)').value
            || document.querySelector('.price .custom_text_input:nth-child(3)').value) {
            params.set('price',
                `${document.querySelector('.price .custom_text_input:nth-child(1)').value.replace(/,/, '.')},
                ${document.querySelector('.price .custom_text_input:nth-child(3)').value.replace(/,/, '.')}`);
        }

        if (document.querySelector('.length .custom_text_input:nth-child(1)').value
            || document.querySelector('.length .custom_text_input:nth-child(3)').value) {
            params.set('length',
                `${document.querySelector('.length .custom_text_input:nth-child(1)').value.replace(/,/, '.')},
                ${document.querySelector('.length .custom_text_input:nth-child(3)').value.replace(/,/, '.')}`);
        }

        if (document.querySelector('.width .custom_text_input:nth-child(1)').value
            || document.querySelector('.width .custom_text_input:nth-child(3)').value) {
            params.set('width',
                `${document.querySelector('.width .custom_text_input:nth-child(1)').value.replace(/,/, '.')},
                ${document.querySelector('.width .custom_text_input:nth-child(3)').value.replace(/,/, '.')}`);
        }

        if (document.querySelector('#search') && document.querySelector('#search').value) {
            params.set('search', document.querySelector('#search').value);
        }
        return params;
    }

    
    /**
     * Итоговые значения заголовка 
     * @returns 
     */
    getBasketTotal() {
        let cartValueDiv = document.getElementById('navbar_cart')?.querySelector('.text').querySelector('.value');
        if (!cartValueDiv) return;

        this.django.getBasketTotal().subscribe( (data) => {
            let itemCount = data.response.item__count;
            let itemSumm = data.response.line_amount__sum;            
            cartValueDiv = document.getElementById('navbar_cart')?.querySelector('.text').querySelector('.value');
            if  (cartValueDiv) {                
                if (itemCount > 0) {
                    cartValueDiv.textContent = `${itemCount} тов. / ${itemSumm} Р`;
                } else {
                    cartValueDiv.textContent = 'Нет товаров';
                }
            }
        });
    }


    searchFilter(element, tr) {
        // console.log(element);
        const object = document.querySelector('#search');
        const ARR_PRICE_PARAMS = [];
        document.querySelectorAll('#search').forEach(input => ARR_PRICE_PARAMS.push(+input.value.replace(/,/, '.')));
        let submintElement = document.getElementById("submit-page");
        let params = new URLSearchParams(submintElement.getAttribute('href').split('?')[1]);

        params.delete('page');

        params.set('search', document.querySelector('.search>input').value);

        const newParams = params.toString();
        const targetContainer = document.querySelector('.search');
        const counterDiv = document.querySelector('#find-count-items');
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${newParams.length ? '?' + newParams : ''}`;
        submintElement.setAttribute('href', newurl);
        counterDiv.setAttribute('href', newurl);
        const getCat$ = this.django.getCategorySelection().subscribe(
            (data) => {
                // console.log(data);
            }, (resp) => {
                // console.error(resp);
                return resp
            }
            , () => getCat$.unsubscribe()
        );
        const getMen$ = this.django.getCategoryMenu(newurl).subscribe(
            resp => {
                // console.log(resp);
                let menuEnable = resp.response.data;
                document.querySelectorAll('.menufilter').forEach(element => {   //заменил на querySelectorAll так как он возвращает nodesList
                    if (!menuEnable || !menuEnable.includes(+element.dataset.id)) {
                        element.classList.remove('menugray');
                    } else {
                        element.classList.add('menugray');
                    }
                });
            },
            (resp) => {
                // console.error(resp);
                return resp;
            }
            , () => getMen$.unsubscribe()
        );

        const getCount$ = this.django.getCategoryCount(newurl).subscribe(
            (data) => {
                // console.log(data);
                let element = document.getElementById("find-count-items")
                //TODO: Ошибка        
                // element.querySelector("img").setAttribute('style', 'display:none;');
                element.querySelector(".text").setAttribute('style', 'display:flex;');

                let newDiv = document.createElement('div');
                element.append(newDiv);
                element.querySelector('div').classList.add('value');
                element.querySelector('.value').textContent = `(${data.response.data[0].items_count})`;
                document.querySelector('#submit-page').textContent = `Показать товары (${data.response.data[0].items_count})`;

            }, (resp) => {
                // console.error(resp);
                return resp
            }
            , () => getCount$.unsubscribe()
        );
        try {
            location.href = document.querySelector('#submit-page').getAttribute('href');
        } catch{
            location.reload();
        }
        
    }


    getFilterData(newurl, obj, counterDiv, targetContainer) {
        // const getCat$ = this.django.getCategorySelection().subscribe(
        //     (data) => {
        //         // // console.log(data);
        //     }, (resp) => {
        //         // console.error(resp);
        //         return resp
        //     }
        //     , () => getCat$.unsubscribe()
        // );
        // console.log(newurl);   
        console.log(obj)
        const getMen$ = this.django.getCategoryMenu(newurl).subscribe(
            resp => {   
                // console.log(resp);       
                let menuEnable = resp.response.data || [] ;
                // console.log(menuEnable)
                const ARR_BAS = [];
                obj.closest('.target_container')
                    .querySelectorAll('.item_list_input>input')
                        .forEach(id => ARR_BAS.push(+id.dataset.id));

                // console.log(ARR_BAS);                
                document.querySelectorAll('.group_values').forEach(MENU => {
                    const elementNode = MENU.querySelectorAll('.item_list_input');
                    const GROUP = [...elementNode];     
                    elementNode.innerHTML = '';
                    
                    ([...GROUP.filter(itemMenu => {
                        if(menuEnable.includes(+itemMenu?.dataset.id)){
                            itemMenu.classList.add('menugray');
                            return true;
                        } else {
                            itemMenu.classList.remove('menugray');
                            return false;
                        }
                    }),
                    ...GROUP.filter(itemMenu => !menuEnable.includes(+itemMenu?.dataset.id))])
                    .forEach(item => {
                        MENU.appendChild(item)
                    });
                });
                

                // document.querySelectorAll('.menufilter').forEach(element => {   //заменил на querySelectorAll так как он возвращает nodesList

                //     if (ARR_BAS.includes(+element.dataset.id)) return;
                //     if (!menuEnable || !menuEnable.includes(+element.dataset.id)) {
                //         element.classList.remove('menugray');
                //     } else {
                //         element.classList.add('menugray');
                //     }
                // });
                // // console.log(resp);
                // let menus = document.getElementsByClassName('menufilter');



                // for (let index = 0; index < menus.length; index++) {

                //         const element = menus[index];                         
                //         let id = parseInt(element.dataset.id);
                //         if (!menuEnable) {
                //             element.classList.remove('menugray'); 
                //         } else {
                //             if (menuEnable.indexOf(id) == -1) {                                                                    
                //                 element.classList.remove('menugray');                          
                //             } else {                        
                //                 element.classList.add('menugray');    
                //             }    
                //         }
                // }                   
            },
            (resp) => {
                // console.error(resp);
                return resp;
            }
            , () => getMen$.unsubscribe()
        )

        // console.log(newurl)
        const getCount$ = this.django.getCategoryCount(newurl).subscribe(
            (data) => {
                console.log(data)
                let element = document.getElementById("find-count-items")
                
                element.querySelector("img").setAttribute('style', 'display:none;');
                element.querySelector(".text").setAttribute('style', 'display:flex;');

                let newDiv = document.createElement('div');
                element.append(newDiv);
                element.querySelector('div').classList.add('value');
                element.querySelector('.value').textContent = `(${data.response.data[0].items_count})`;
                document.querySelector('#submit-page').textContent = `Показать товары (${data.response.data[0].items_count})`;
                // element.append(`<div class="value">${data.response.data[0].items_count}</div>`);


                

                

                // // console.log(containerCoords);

                

                // setTimeout(() => counterDiv.classList.remove('show'), 2000);


                //////////////////////////

            }, (resp) => {
                // console.error(resp);
                return resp
            }
            , () => getCount$.unsubscribe()
        );
    }


    basketUpdate(id, qty) {
        this.django.basketUpdateQty(id, qty)
            .subscribe((resp) => {
                if (resp.status == 201) {
                    // console.log(resp.status);
                }
            }
                , (resp) => {
                    // console.log(resp)
                    return resp
                });

    }
    basketSave(obj, type) {

        let els = document.getElementsByClassName('need_count');
        Array.prototype.forEach.call(els, function (el) {

            api.basketUpdate(el.dataset.id, el.value);
        });
        document.querySelector('.basket_save').classList.remove('show');
    }

    setSearchText() {
        let element = window.document.getElementById('search');
        let loc = window.location; // ? indow.location
        let params = new URLSearchParams(loc.search); // loc нигде не используется, можно просто  new URLSearchParams(window.location.search) 
        let value = element.value
        params.set('search', value)
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
        window.history.pushState({ path: newurl }, '', newurl);
        location.reload()
    }
    setNavigetType(obj) {
        let naviType = obj.dataset.nav;
        let params = new URLSearchParams(window.location.search); // loc нигде не используется, можно просто  new URLSearchParams(window.location.search)         
        // console.log(params);
        params.set('navitype', naviType);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
        // console.log(newurl);
        location = newurl

        // window.history.pushState({ path: newurl }, '', newurl);
        // location.reload()
    }
    categoryConfirm() {
        location.reload()
    }
    openBasketPopUp(obj) {
        this.django.getItemBasketPopup(obj.dataset.id).subscribe(
            (data) => {
                let item = document.getElementById(`popup_for_current_item`);

                if (item) {
                    document.querySelector('body').setAttribute('style', 'overflow:hidden;');
                    item.innerHTML = data.response.data;
                    item.classList.add('show');
                }
            },
            (resp) => {
                return resp
            }
        );
    }

    closeBasketPopUp() {
        let popup = document.getElementById(`popup_for_current_item`)
        if (popup.classList.contains('show')) {
            popup.classList.remove('show');
            document.querySelector('body').removeAttribute('style', 'overflow:hidden;')
        }
    }

    getMenu(obj) {
        this.django.getCategoryMenu('').subscribe(
            (resp) => {
                // console.log(resp)
            },
            (resp) => {
                // console.error(resp);
                return resp;
            }
        )

    }
    /**
     * добавляет номенклатуру 
     * @param {*} obj 
     */
    addItemBasket(obj) {
        let element = window.document.getElementById(obj.dataset.qty);
        
        // // console.log('val = ' + element.value);
        // // console.log('typeof = ' + typeof element.value);

        if (!!element && element.value) {
            this.django.addItemBasket(element.dataset.id
                , element.value
                , element.dataset.unit || null
                , element.dataset.loc || null
                , element.dataset.tone || null
                , element.dataset.caliber || null)
                .subscribe(
                    (resp) => {
                        if (resp.status == 201) {
                            // alert(' Номенклатураа добавлена! ');

                            const container = document.createElement('div');
                            const msg = document.createElement('div');

                            container.setAttribute('id', 'msg_container');
                            container.classList.add('msg_container');

                            msg.classList.add('msg_item');
                            msg.setAttribute('onclick', 'closeMsgItem(event);');
                            msg.textContent = "Номенклатура добавлена в корзину";

                            if (!document.getElementById('msg_container')) document.querySelector('body').appendChild(container);

                            document.getElementById('msg_container').appendChild(msg);                            
                            this.getBasketTotal();
                            
                            

                        }
                    }
                );
        }
    }
    /**
     * удаление из карзины номенклатуры  
     * @param {*} id 
     */
    removeFromBasket(id) {
        this.django.removeFromBasket(id).subscribe(
            (resp) => {
                if (resp.status == 202) {
                    let element = window.document.getElementById(`basket-${id}`)
                    let itemListCount = window.document.querySelectorAll('.basket_list_item').length;
                    element.remove();
                    this.getBasketTotal();
                    if (itemListCount <= 1) {
                        document.querySelector('.basket_without_prods').classList.add('show');
                        document.querySelector('.basket_with_prods').classList.remove('show');
                    }

                }
            },
            (resp) => {
                // console.log(resp)
                return resp
            }
        );

    }

    sendLinkEmail(obj) {
        // console.log(obj);
        this.django.sendLinkEmail(obj.dataset.type).subscribe(
            
            (resp) => {
                let sendMailPopUP = document.querySelector('#popup_mail_send');
                if (resp.response.state == 'NOT EMAIL'){
                    sendMailPopUP.textContent = "У вас не указана информация о почте, пожалуйста перейдите в личный кабинет,\n\
                    раздел изменить профиль и укажиет информацию о почте.";
                } 
                if (resp.response.state == 'OK') { 
                    sendMailPopUP.textContent = "Вам на почту отправлено письмо.";
                }
                if (resp.response.state == 'MANY USERS'){
                    sendMailPopUP.textContent = "На данную почту зарегистрировано несколько пользователей,"
                    "пожалуйста, обратитесь в поддержку.";
                }
                if (resp.response.state == 'ACCOUNT DOES NOT EXIST'){
                    sendMailPopUP.textContent = "Аккаунт с такой почтой не существует.";
                }
                if (resp.status == 200) {
                    sendMailPopUP.style.display = 'block';
                    setTimeout(() => {
                        sendMailPopUP.style.display = 'none';
                    }, 7000);
                }
            },

            (resp) => {
                let sendMailPopUP = document.querySelector('#popup_mail_send');
                sendMailPopUP.textContent = "При отправке письма произошла ошибка."
                sendMailPopUP.style.display = 'block';
                setTimeout(() => {
                    sendMailPopUP.style.display = 'none';
                }, 3000);
                
                
                return resp
            }
        );

    }
    changeRegions(name) {
        this.django.changeRegions(name).subscribe(
            (resp) => {
                let regionDivList = document.querySelectorAll('.region_div');
                regionDivList.forEach(el => {
                    el.textContent = resp.response.name_reg;
                });                
                location.href = resp.response.redirect; 
            },
        );
    }
    setCategoryTypeNavigate(type) {
        return this.django.setCategoryTypeNavigate(type)
            .subscribe(
                (resp) => {
                    // console.log(resp)
                },
                (resp) => {
                    // console.log(resp)
                    return resp
                }
            )
    }
    basketConfirm() {
        let element = window.document.getElementById('descr-for-sales');        
        window.document.getElementById('cart_order_apply').removeAttribute('onclick');
        return this.django.basketConfirm(element.value)
            .subscribe(
                (resp) => {                    
                    location.href = resp.response.url;
                },
                (resp) => {
                    // console.log(resp)
                    return resp
                }
        )
    }
    getItems() {
        let element = window.document.getElementById('search');        
        if (window.rxjs_get.items) {
            window.rxjs_get.items.unsubscribe();
        }

        window.rxjs_get.items = this.django.getItems(element.value)
            .subscribe(
                (resp) => {
                    // console.log(resp.response.data)
                },
                (resp) => {
                    // console.log(resp)
                    return resp
                }
            )

    }
    generatePrice() {
        return this.django.generatePrice()
            .subscribe(
                (resp) => {
                    // console.log('dddd');
                    const link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = window.URL.createObjectURL(resp.response);
                    link.setAttribute('download', 'price.xls');
                    document.body.appendChild(link);
                    link.click();
                    // setTimeout(() => {
                    //     link.remove();    
                    // }, 1);
                },
                (resp) => {
                    // console.log(resp)
                    return resp
                }
            )
    }

    /**
     * @param  {} object - target
     * @param  {} element - element arr
     */
    addFilterPrice(e) {
        const ARR_PRICE_PARAMS = [];
        document.querySelectorAll('.price input').forEach(input => ARR_PRICE_PARAMS.push(+input.value.replace(/,/, '.')));
        let submintElement = document.getElementById("submit-page");
        let params = new URLSearchParams(submintElement.getAttribute('href').split('?')[1]);
        const PARAMS_STRING = ARR_PRICE_PARAMS.sort((a, b) => {
            if (a > b) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            return 0;
        }).reduce((accumulate, curr) => `${accumulate},${curr}`);
        params.delete('page');
        params.set('price', PARAMS_STRING);
        const newParams = params.toString();
        const targetContainer = e.target.closest('.target_container');
        // console.log(targetContainer);
        const counterDiv = document.querySelector('#find-count-items');
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${newParams.length ? '?' + newParams : ''}`;
        submintElement.setAttribute('href', newurl);
        counterDiv.setAttribute('href', newurl);
        // this.getFilterData(newurl, object, counterDiv, targetContainer);

        const getCount = this.django.getCategoryCount(newurl).subscribe(
            (data) => {
                // console.log('getCount');
                // console.log(data);

                counterDiv.querySelector("img").setAttribute('style', 'display:none;');
                counterDiv.querySelector(".text").setAttribute('style', 'display:flex;');

                let newDiv = document.createElement('div');
                counterDiv.append(newDiv);
                counterDiv.querySelector('div').classList.add('value');
                counterDiv.querySelector('.value').textContent = `(${data.response.data[0].items_count})`;
            }, 
            (resp) => resp,
            () => getCount.unsubscribe()
        );

    }


    /**
     * @param  {} object - target
     * @param  {} element - element arr
     */
    addFilterWidth(e) {
        const ARR_PRICE_PARAMS = [];
        document.querySelectorAll('.width input').forEach(input => ARR_PRICE_PARAMS.push(+input.value.replace(/,/, '.')));
        let submintElement = document.getElementById("submit-page");
        let params = new URLSearchParams(submintElement.getAttribute('href').split('?')[1]);
        const PARAMS_STRING = ARR_PRICE_PARAMS.sort((a, b) => {
            if (a > b) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            return 0;
        }).reduce((accumulate, curr) => `${accumulate},${curr}`);
        params.delete('page');
        params.set('width', PARAMS_STRING);
        const newParams = params.toString();
        const targetContainer = e.target.closest('.target_container');
        const counterDiv = document.querySelector('#find-count-items');
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${newParams.length ? '?' + newParams : ''}`;
        submintElement.setAttribute('href', newurl);
        counterDiv.setAttribute('href', newurl);
        // this.getFilterData(newurl, object, counterDiv, targetContainer);

        const getCount = this.django.getCategoryCount(newurl).subscribe(
            (data) => {
                // console.log('getCount');
                // console.log(data);

                counterDiv.querySelector("img").setAttribute('style', 'display:none;');
                counterDiv.querySelector(".text").setAttribute('style', 'display:flex;');

                let newDiv = document.createElement('div');
                counterDiv.append(newDiv);
                counterDiv.querySelector('div').classList.add('value');
                counterDiv.querySelector('.value').textContent = `(${data.response.data[0].items_count})`;
            }, 
            (resp) => resp,
            () => getCount.unsubscribe()
        );

    }

    /**
     * @param  {} object - target
     * @param  {} element - element arr
     */
    addFilterLength(e) {
        // console.log(e);
        const ARR_PRICE_PARAMS = [];
        document.querySelectorAll('.length input').forEach(input => ARR_PRICE_PARAMS.push(+input.value.replace(/,/, '.')));
        let submintElement = document.getElementById("submit-page");
        let params = new URLSearchParams(submintElement.getAttribute('href').split('?')[1]);
        const PARAMS_STRING = ARR_PRICE_PARAMS.sort((a, b) => {
            if (a > b) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            return 0;
        }).reduce((accumulate, curr) => `${accumulate},${curr}`);

        params.delete('page');
        params.set('length', PARAMS_STRING);

        const newParams = params.toString();
        const targetContainer = e.target.closest('.target_container');
        // console.log(targetContainer);
        const counterDiv = document.querySelector('#find-count-items');
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${newParams.length ? '?' + newParams : ''}`;
        submintElement.setAttribute('href', newurl);
        counterDiv.setAttribute('href', newurl);

        const getCount = this.django.getCategoryCount(newurl).subscribe(
            (data) => {
                // console.log('getCount');
                // console.log(data);

                counterDiv.querySelector("img").setAttribute('style', 'display:none;');
                counterDiv.querySelector(".text").setAttribute('style', 'display:flex;');

                let newDiv = document.createElement('div');
                counterDiv.append(newDiv);
                counterDiv.querySelector('div').classList.add('value');
                counterDiv.querySelector('.value').textContent = `(${data.response.data[0].items_count})`;
            }, 
            (resp) => resp,
            () => getCount.unsubscribe()
        );


        // this.getFilterData(newurl, object, counterDiv, targetContainer);

    }
    lookUpForm( obj ) {
        let lookup = obj.dataset.lookup;                
        let control = obj.dataset.control;
        this.django.lookUp(`lookup_${lookup}`, control).subscribe(
            (resp) => {
                let modal_html = document.getElementById('modal-form'); 
                let modal_content = document.getElementById('model-content'); 
                modal_content.innerHTML = resp.response;
                let modal = new bootstrap.Modal(modal_html, )
                modal.show()
            }, 
            resp => {
                // console.log('error: ', resp);
                return resp;
            }
        );

    } 
    setLookUpResult(obj) {
        let modal_html = document.getElementById('modal-form');         
        let element = window.document.getElementById(obj.dataset.control);        
        element.value = obj.querySelector('td.lookupName').innerHTML
        
        let modal = new bootstrap.Modal(modal_html, )

        modal.toggle();
        modal.handleUpdate();
        modal.hide();
        document.querySelector('.modal-backdrop').remove();

    } 
    getAxClassif( obj ) {
        let id = obj.dataset.id 
        return  this.django.getAxclassif(id).subscribe(
            (resp) => {                
                    // console.log(resp);
                    let element = document.querySelector('#axapta-classif');
                    element.innerHTML = resp.response;
                    // axapta-classif
            }, 
            resp => {
                // console.log('error: ', resp);
                return resp;
            }
        );  

        
    }
    getCategoyMenuList(obj)  {
        let menuId =  obj.dataset.id;        
        let href            = document.getElementById("submit-page")?.getAttribute('href');

        return  this.django.getCategoyMenuList(menuId, href).subscribe(
            (resp) => {     
                // console.log(resp);
                resp.response.data.forEach( ( el ) => {
                    // console.log(el);
                });
                obj.appendChild('div')
            }, 
            resp => {
                // console.log('error: ', resp);
                return resp;
            }
        );
    }
    unitConvertList(itemid, fromUnitId, qty) {
        return this.django.unitConvertList(itemid, fromUnitId, qty); 
    }
    refreshBasketLine(baskeId, unitId, qty){
        return this.django.refreshBasketLine(baskeId, unitId, qty)
    }
    /**
     * открытие модальной формы выбора региона 
     */
    openModalRegion() {

        fromEvent(document.querySelector('#regionInput'), 'keyup')
        .subscribe((e) => {
            let obj  = e.currentTarget;               
            this.pageParams.el_regions_dropdown.classList.add('show');
            if (obj.value == '') {
                this.pageParams.el_regions_dropdown.classList.remove('show');
            }       
            let find_cities = this.pageParams.regions_list.cities.filter((city)=> {
                 return city.name_ru.toLowerCase().match(`${obj.value.toLowerCase()}`)  ;
            }) 
    
            this.pageParams.el_regions_dropdown.textContent = '';
    
            for (let i in find_cities ) {
                let city = find_cities[i]                        
                let element =  document.createElement('div')
                // , {"class": 'item', "data-bs-dismiss": "modal", "data-id": city.id});            
                element.textContent = city.name_ru;
                element.setAttribute("class",  "item region-city");
                element.setAttribute("data-bs-dismiss",  "modal");
                element.setAttribute("data-id",  city.id);
                element.setAttribute("data-type",  0);
                fromEvent(element, 'click')
                        .subscribe((e) => {         
                            DjangoApi.prototype.setRegion(city.id, 0).subscribe((resp) => {                     
                                document.location = resp.response.url;
                            });
                        });
                this.pageParams.el_regions_dropdown.appendChild(element)
            }
        }) 


        this.django.getRegions().subscribe((resp) => {                                 
            this.pageParams['regions_list'] = JSON.parse(resp.response.data);                    
            let elDropdown = document.querySelector('.town_input').querySelector('.custom_input').querySelector('.dropdown_list');                
            this.pageParams['el_regions_dropdown'] = elDropdown;        
            this.pageParams.el_regions_dropdown.classList.remove('show');
            let elmModal = document.querySelector('#regionModal');        
            let elDistricts =   document.querySelector('.all_towns__districts');                            
            elDistricts.textContent = '';
            for (let i in this.pageParams.regions_list.districts) {                
                let distr = this.pageParams.regions_list.districts[i]
                // <div class="item">Нижегородская область</div>
                let element =  document.createElement('div')
                if (i == 0) {
                    element.setAttribute("class",  "item active item-region");                
                    this.refreshRegion(distr)
                } else {
                    element.setAttribute("class",  "item item-region");                
                }

                element.textContent = distr.name_ru;
                
                element.setAttribute("data-id",  distr.id);                
                element.setAttribute("data-type",  2);                
                fromEvent(element, 'click')
                .subscribe((e) => {                                 
                    this.refreshRegion(distr)
                });
                elDistricts.appendChild(element)
            }
            let elCitiesShop =   document.querySelector('.big_cities');                                
            elCitiesShop.textContent = '';
            
            for (let i in this.pageParams.regions_list.shops) {                

                let shop = this.pageParams.regions_list.shops[i]
                // <div class="item">Нижегородская область</div>
                // <span class="item" data-bs-dismiss="modal">Москва</span>
                let element =  document.createElement('span')                    
                element.setAttribute("class",  "item item-region");                
                element.textContent = shop.name_ru;                    
                fromEvent(element, 'click')
                .subscribe((e) => {                                 
                    DjangoApi.prototype.setRegion(shop.id, 0).subscribe((resp) => {                     
                        document.location = resp.response.url;
                    });
                });
                elCitiesShop.appendChild(element)
            }


            
            (new Modal(elmModal)).show();
    });  

    }
    refreshRegion(district) {
        let elRegion = document.querySelector('.all_towns__regions');
        elRegion.textContent = '';
        let distr_regions = this.pageParams.regions_list.regions.filter((region) => {
            return region.group_id ===  district.id
        })
        for (let i in distr_regions) {                
            let region = distr_regions[i]            
            let element =  document.createElement('div')
            if (i == 0) {
                element.setAttribute("class",  'item active item-region');         
                this.refreshCities(region)
            } else {
                element.setAttribute("class",  'item item-region');         
            }    
            element.textContent = region.name_ru;
            element.setAttribute("data-id",  region.id);               
            element.setAttribute("data-type",  1);        
            fromEvent(element, 'click')
                    .subscribe((e) => {                                 
                        this.refreshCities(region)
                    });       
            elRegion.appendChild(element)

        }    

    }
    refreshCities(region) {
        let elCities = document.querySelector('.all_towns__towns');
        elCities.textContent = '';
        let distr_regions_cities = this.pageParams.regions_list.cities.filter((city) => {
            return city.region_id === region.id
        })
        for (let i in distr_regions_cities) {                
            let city = distr_regions_cities[i]            
            let element =  document.createElement('div')
            if (i == 0) {
                element.setAttribute("class",  'item active item-region');                         
            } else {
                element.setAttribute("class",  'item item-region');         
            }    
            element.textContent = city.name_ru;
            element.setAttribute("data-id",  city.id);              
            element.setAttribute("data-type",  0);  
            fromEvent(element, 'click')
                    .subscribe((e) => {         
                        DjangoApi.prototype.setRegion(city.id, 0).subscribe((resp) => {                     
                            document.location = resp.response.url;
                        });
                    });
            elCities.appendChild(element)
        }            
    }
    setRegion(id, type) {
        this.django.setRegion(id, type).subscribe((resp) => {                     
            document.location = resp.response.url;
        });
    }
        
}
