
/**
 * Закрывает всплывающее сообщение о добавлении товара в корзину
 * @param {*} e 
 */
export function closeMsgItem(e) {
    e.target.remove();
    let msgItems = document.querySelectorAll('.msg_item').length;
    if (msgItems === 0) 
        document.getElementById('msg_container').remove();
}

/**
 * Пересчитывает и выводит сумму на странице номенклатуры в зависимости от указанного юзером количества
 * @param {*} e 
 */
export function countSummOfItems (e) {
    let div, summ, qty, price;
    div = e.target.closest('.counter').parentNode.querySelector('.summ');
    price = parseFloat(document.querySelector('.item_details_price_val').textContent.replace(',','.'));
    summ = 0;
    qty = 0;

    if(div && price) {
        qty = parseFloat(e.target.closest('.counter').querySelector('input').value);
        summ = (qty * price).toFixed(2);
        div.querySelector('.value').textContent = summ;
    }
}

/**
 * Открывает выползающие меню в мобилке (общая менюха и лк)
 * @param {*} e 
 */
export function mobileMenuOpen(e) {
    if (!e.target.closest('.item').querySelector('.fixed_container').classList.contains('show')) {
        e.target.closest('.item').querySelector('.fixed_container').classList.add('show');    
        document.querySelector('body').setAttribute('style', 'overflow:hidden;');
    }
}
/**
 * Закрывает выползающие меню в мобилке (общая менюха и лк)
 */
export function mobileMenuClose() {
    let list = document.querySelectorAll('.fixed_container');
    list.forEach(el => {
        if (el.classList.contains('show')) el.classList.remove('show');
    })
    document.querySelector('body').removeAttribute('style');
}
/**
 * Открывает/закрывает панель фильтров в мобилке 
 */
export function filterPanelToggle(){
    let panel;
    panel = document.querySelector('.filter_panel');
    if (panel) {
        panel.classList.toggle('show');
        if (panel.classList.contains('show')) {
            document.querySelector('body').setAttribute('style', 'overflow:hidden');
        } else {
            document.querySelector('body').removeAttribute('style');
        }
    };
}
/**
 * Фильтрация элементов в зависимости от введенного в инпут значения
 * @param {*} e 
 */
export function filterByTextType(e){
    var input, filterText, propList, propName;
    input = e.target;
    filterText = input.value.toLowerCase();
    propList = e.target.parentNode.querySelector('.group_values').childNodes;

    propList.forEach(function(element){
        propName = element.querySelector('label').textContent;
        propName.toLowerCase().indexOf(filterText) > -1
            ? element.style.display = ''
            : element.style.display = 'none';
    });
}
/**
 * Фильтрация списка регионов в зависимости от введенного в инпут значения
 * @param {*} e 
 */
export function filterRegions(e){    
    let searchText = e.target.value.toLowerCase();
    let itemsList = document.querySelector('.regions_list').querySelectorAll('.col-3');
    itemsList.forEach(el => {
        let itemsListValue = el.querySelector('.regions_list_item').textContent;
        itemsListValue.toLowerCase().indexOf(searchText) > -1
            ? el.style.display = ''
            : el.style.display = 'none';
    })
}
/**
 * Переключалка между вкладками товары/подкатегории ---- НЕ АКТУАЛЬНО тк теперь отдельные страницы
 * @param {*} e 
 */
export function changeProductView(e){
    var trigger, triggerList;
    trigger = e.target.closest('.view_controls_btn');    
    triggerList = document.querySelectorAll('.view_controls_btn');
    
    if (!trigger.classList.contains("active")) {
        triggerList.forEach(element => {
            element.classList.toggle('active');
        });
        document.querySelector('.items_view').classList.toggle('active');
        document.querySelector('.items_view').classList.toggle('hide');
        document.querySelector('.categories_view').classList.toggle('active');
        document.querySelector('.categories_view').classList.toggle('hide');
    }
}

// window.onload = function () {
//     //updateBasketTotal();
//     checkSortParams();
// };

/**
 * Считает динамически общую сумму товаров на странице корзины, складывая цены и количество каджой из позиций.
 * Вызывается при изменении количесва товара на странице корзины
 */
export function updateBasketTotal() {
    let sumList = document.querySelectorAll('.item_summ');
    let total = 0;
    // let titleCount;
    // titleCount = document.querySelector('.cart_title_with_count').querySelector('span');
    sumList.forEach(element => {
        let sum = parseFloat(element.querySelector('.value').textContent.replace(',','.'));
        total += sum;
    });
    total = total.toFixed(2);
    if (document.querySelector('.basket_total_info')) document.querySelector('.basket_total_info').querySelector('.value').textContent = `${total} P`;
    if (sumList.length !== 0 && document.querySelector('.cart_title_with_count').querySelector('span')) {
        document.querySelector('.cart_title_with_count').querySelector('span').textContent = `- ${sumList.length}`;
    } 
}
/**
 * Ввод количесва товара в инпут... Блочит буквы, не дает ввести значение больше текущего остатка (если необходимо)
 * @param {*} e 
 */
export function enterCost(e){
    el = e.target;
    currentValue = el.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    if (el.hasAttribute('maxval')){
        maxValue = parseFloat(el.getAttribute('maxval').replace(',', '.'));
        if (parseFloat(currentValue) >= maxValue) {
            el.value = maxValue;
        } else {
            currentValue ? el.value = currentValue : el.value = '';
        }
    } else {
        currentValue ? el.value = currentValue : el.value = '';
    }

    if (el.matches('.need_count')) {
        updateItemSum(el);
    }
    
}

/**
 * Увеличивает колво товара при клике на +.... не дает ввести значение больше текущего остатка (если необходимо)
 * @param {*} e 
 */
export function counterPlus(e) {

    input = e.target.parentNode.parentNode.querySelector('input');
    currentValue = parseFloat(input.value);
    
    if (!currentValue) currentValue = 0;

    currentValue += 1;

    if (input.hasAttribute('maxval')) {
        maxValue = parseFloat(input.getAttribute('maxval').replace(',', '.'));
        if (currentValue >= maxValue){
            input.value = maxValue.toFixed(2); 
            input.setAttribute('value', maxValue.toFixed(2));       
        } else {
            input.value = currentValue.toFixed(2);
            input.setAttribute('value', currentValue.toFixed(2));
        }
    } else{
        input.value = currentValue.toFixed(2);
        input.setAttribute('value', currentValue.toFixed(2));
    }

    if (input.matches('.need_count')) {
        updateItemSum(input);
    }
    // пересчет едениц 
    
}

/**
 * Уменьшает колво товара при клике на -.... не дает ввести значение больше текущего остатка (если необходимо)
 * @param {*} e 
 */
export function counterMinus(e) {

    input = e.target.parentNode.parentNode.querySelector('input');
    currentValue = parseFloat(input.value);
    
    if (!currentValue) currentValue = 0;

    currentValue -= 1;
    if (currentValue <= 0){
        input.value = 0;
        input.setAttribute('value', 0);        
    } else {
        input.value = currentValue.toFixed(2);
        input.setAttribute('value', currentValue.toFixed(2));
    }

    if (input.matches('.need_count')) {
        updateItemSum(input);
    }
}

/**
 * Переключает сортировку и подставляет стили для выбранного значения
 * @param {*} elem 
 */
export function sortItems(elem) {
    let selectedItem = elem.target;
    let allItemsList = elem.target.parentNode.querySelectorAll('.item');
    
    if (!selectedItem.classList.contains('selected')){
        allItemsList.forEach(el => {
            if (el.classList.contains('selected')) el.classList.remove('selected');
        });
        selectedItem.classList.add('selected');
        let sortProperty = selectedItem.getAttribute('sortProp');
        let currentLoc = window.location.search;
        let currentUrlParams = new URLSearchParams(currentLoc);
        currentUrlParams.delete('order');
        currentUrlParams.append('order', sortProperty)        
        
        window.location.search = currentUrlParams.toString();
    }
}
/**
 * Проверяет текущее значение сортировки, чтобы прорисовать стили при загрузке страницы (вызывается в onload)
 */
export function checkSortParams() {
    let currentUrlParams = new URLSearchParams(window.location.search);
    let sortItemsDiv = document.querySelector('.items_view_sort');
    let sortItemsList; 
    
    if (sortItemsDiv) sortItemsList = sortItemsDiv.querySelectorAll('.item');

    if (sortItemsList && currentUrlParams.has('order')) {
        sortItemsList.forEach(el => {
            if (el.getAttribute('sortProp') === currentUrlParams.get('order')) {
                el.classList.add('selected');
            }
        });
    }
}
/**
 * Считает и обновляет сумму каждой из позиций в списке на странице корзины (цена*кол-во)
 * Вызывается при изменении кол-ва товара
 * @param {*} element 
 */
export function updateItemSum (element) {
    let qty = parseFloat(element.value);
    let price = parseFloat(element.parentNode.parentNode.parentNode.querySelector('.item_price').querySelector('.value').textContent.replace(',','.'));
    let sum;
    if(qty){
        // sum = ;
        sum = (qty * price).toFixed(2);
    }else{
        sum = 0;
    }
    
    element.parentNode.parentNode.parentNode.querySelector('.item_summ').querySelector('.value').textContent = `${sum} P`;
    document.querySelector('.basket_save').classList.add('show');
    updateBasketTotal();
}
/**
 * Выводит красную плавающую кнопку "Показать товары" при выборе параметров в фильтре
 * @param {*} e 
 */
export function showCountItemsBtn (e) {
    let counterDiv;
    let targetContainer = e.target.closest('.target_container');

    counterDiv = document.getElementById("find-count-items");
    counterDiv.querySelector("img").setAttribute('style', 'display:flex;');
    counterDiv.querySelector(".text").setAttribute('style', 'display:none;');
    if (counterDiv.querySelector('.value')) counterDiv.querySelector('.value').remove();

    let containerCoords = {
        x: targetContainer.getBoundingClientRect().right + pageXOffset + 30,
        y: targetContainer.getBoundingClientRect().top + pageYOffset + targetContainer.offsetHeight / 2
    }

    counterDiv.classList.add('show');

    counterDiv.style.left = containerCoords.x + 'px';
    counterDiv.style.top = targetContainer.getBoundingClientRect().top + pageYOffset + targetContainer.offsetHeight / 2 - counterDiv.offsetHeight / 2 + "px";

    updateFuckinTimer(500);
}

var interval;
/**
 * Скрывает красную плавающую кнопку "Показать товары" спустя заданое время. 
 * Обнуляет таймер скрытия, если не было скрыто и был выбран еще один параметр
 * @param {*} seconds 
 */
export function updateFuckinTimer (seconds) {
    
    let div = document.querySelector('#find-count-items');
    let timeValue = seconds;
     
    
    clearInterval(interval);

    interval = setInterval(() => {
        if(timeValue > 0) timeValue--;
        if (timeValue === 0) {
            div.classList.remove('show');
            clearInterval(interval);  
        }
    }, 1000);
    
   
}




/**
 * Переключает текущий дропдаун и закрывает остальные, если были открыты
 * @param {*} name 
 */
export function toggleDropdown(name) {

    var dropdownList = document.querySelectorAll('.dropdown_body');
    var i;

    for (i = 0; i < dropdownList.length; i++) {
        if (dropdownList[i].classList.contains(name)) {
            dropdownList[i].classList.toggle('show')
        } else {
            dropdownList[i].classList.remove('show')
        }
    }

}

/**
 * Скрывает дропдаун при клике вне его области
 * @param {*} e 
 */
window.onclick = function(e) {
        
    if (!e.target.matches('.dropdown_btn') && !e.target.closest('.dropdown_btn')){
        var dropdownList = document.querySelectorAll('.dropdown_body');
        var i;

        for (i = 0; i < dropdownList.length; i++) {
            if (dropdownList[i].classList.contains('show')) dropdownList[i].classList.remove('show')
        }

    }

    if (document.getElementById('msg_container') && !e.target.matches('.msg_item')) {
        document.getElementById('msg_container').remove();
    };

}

// // toggle menu on mobile devices
// export function toggleMenu() {
//     if (document.querySelector('.leftbar')){
//         if (!document.querySelector('.leftbar').matches('.open')){
//             document.querySelector('.leftbar').classList.add('open')
//             document.querySelector('.main').classList.add('open')
//             document.querySelector('.bottom_nav').classList.add('shift')
//             openPopupMask(true);
//         } else{
//             document.querySelector('.leftbar').classList.remove('open')
//             document.querySelector('.main').classList.remove('open')
//             document.querySelector('.bottom_nav').classList.remove('shift')
//             openPopupMask(false);
//         }
//     }
    
// }

// export function openPopupMask(bool){
//     if (bool) {
//         document.querySelector('.mask').classList.add('open');
//         document.querySelector('body').setAttribute('style', 'overflow:hidden;')
//     } else {
//         document.querySelector('.mask').classList.remove('open');
//         document.querySelector('body').removeAttribute('style', 'overflow:hidden;')
//     }
// }

// itemcard

// export function openItemCardPopup(elem){
    
//     closeAllItemCardPopup();

//     var item = document.getElementById('card_popup_' + elem);
//     if (item) {
//         item.classList.add('show');
//         document.querySelector('body').setAttribute('style', 'overflow:hidden;');
//     }
// }

// export function closeAllItemCardPopup() {
//     var popupList = document.querySelectorAll('.card_item_popup');
//     popupList.forEach(function(item){
//         item.classList.remove('show');
//     });
//     document.querySelector('body').removeAttribute('style', 'overflow:hidden;')
// }

// export function tabsToggle(e, id){
    
//     disableTabs(id);
//     tabAttr = e.currentTarget.getAttribute('tabname');
//     e.currentTarget.className += " active";
//     document.getElementById('tb_' + id + '_' + tabAttr).classList.add('active');
// }

// export function disableTabs(id){
//     document.querySelectorAll('.tab_btn.id_' + id).forEach(export function (item) {
//         item.classList.remove('active')
//     });
//     document.querySelectorAll('.tabcontent_item.id_' + id).forEach(export function (item) {
//         item.classList.remove('active')
//     });
// }


/**
 * Переключение вкладок на странице магазины
 * @param {*} e 
 */
export function shopTabsByRegion(e){
    var tabKey = e.target.textContent;
    var tabBtnList = document.querySelectorAll('.shops_region_btn');
    var tabContainers = document.querySelectorAll('.shops_region_tab');

    tabBtnList.forEach(function(element){
        element.classList.remove('active');
    });
    e.target.classList.add('active');
    
    if (tabKey === 'Все регионы') {
        tabContainers.forEach(function(element){
            element.classList.remove('hide')
        });
    } else {
        tabContainers.forEach(function(element){
            element.getAttribute('region') === tabKey
                ? element.classList.remove('hide')
                : element.classList.add('hide');
        });
    }
}

