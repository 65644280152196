"use strict"
const ajax = require('rxjs/ajax')
import { webSocket } from 'rxjs/webSocket';

export class DjangoApi {
    constructor() {
    }    
    connectSocket( callBackRecive ) {
        let djthat = this;
        this.roomId = this.getCookie('roomId');               

        this.token = this.getCookie('csrftoken');               
        this.socket =  webSocket(
            { 
                url :`ws://192.168.9.41:4777/chat/client/${this.roomId}/`,
                openObserver: {
                    next:(event) => {
                      console.log('Connection ok');                    
                    }
                }, 
                closeObserver: {
                    next(event) {                             
                        console.log(callBackRecive);                        
                        djthat.connectSocket( callBackRecive );
                        const customError = { code: 6666, reason: 'Custom evil reason' }
                        console.log(`code: ${ customError.code }, reason: ${ customError.reason }`);
                        /// подпись на посл. сообщения 

                    }
                }    

            }, "11111"
        );                 
        this.socket.subscribe({
            next:  callBackRecive                    
        });        
    }           
    soketNext(msg){
       let data = {
            type: 'chat.message',            
            action: "message", 
            msg_time: Date.now(),
            msg_from: this.roomId, 
            msg_to: this.roomId, 
            data : {
                text: msg
            }
        }
        return this.socket.next(data);
    }  
    getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }    
    exec(action, data, responseType = 'json') {
        let csrftoken = this.getCookie('csrftoken');
        return ajax.ajax(
            {
                url: window.location, // ?  window.location.href
                method: 'POST',
                datatype: 'json',
                responseType: responseType,
                headers: {
                    'X-CSRFToken': csrftoken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json'
                },
                body: {
                    action: action,
                    data: JSON.stringify(data)
                }
            })
    }
        /**
     * Функция пересчета едениц
     * @param {*} idFor 
     * @param {*} fromUnitId 
     * @param {*} toUnitId 
     * @param {*} qty 
     * @returns  {qty: val}
     */
    unitConvert(idfor, fromUnitId, toUnitId, qty) {
        return this.exec('unit_convert', { "id": idfor, "from_unit": fromUnitId, "to_unit_id": toUnitId, "qty": qty });
    }
    /**
     * 
     * @param {*} 
     * @param {*} fromUnitId 
     * @param {*} qty 
     * @returns  json 
     */
    unitConvertList(idFor,fromUnitId, qty) {        
        return this.exec('unit_convert_list', { "id": idFor, "from_unit_id":fromUnitId,  "qty": qty });
    }    


    /**
     * Получение меню категории 
     * @param {*} url      
     * @returns html
     */
    getCategoryMenu(url) {
        return this.exec('get_category_menu', { "url": url });
    }
    /**
     * Получение номенклатур категории  
     * @param {*} url      
     * @returns html
     */
    getCategoryItems(url) {
        return this.exec('get_category_items', { "url": url });
    }

    /**
     * Количество найденых номенклатур
     * @param {*} url 
     * @returns 
     */
    getCategoryCount(url) {

        return this.exec('get_category_item_count', { "url": url });
    }

    getCategorySelection() {
        return this.exec('get_category_selection_list', {});
    }
    /**
     * Способ навигации      
     * @param {*} type 
     * @returns 
     */
    setCategoryTypeNavigate(type) {
        return this.exec('set_category_navigate_type', { 'type': type });
    }

    /**
     * Возвращяет по  пользователю колч позиций и сумму номенклатуры      
     */
    getBasketTotal() {
        return this.exec('get_basket_total', {});
    }
    /**
     * Добавлдение позиции в корзину 
     * @param {*} id - ид. номенклатуры 
     * @param {*} tone - тон 
     * @param {*} caliber - клибр 
     * @param {*} qty - количество 
     * @param {*} unit_id - ид. ед измерения  
     * @param {*} location - склад
     */
    addItemBasket(id, qty = 1, unit_id, location = null, tone = null, caliber = null) {
        return this.exec('add_item_basket', { "id": id, "qty": qty, "location": location, "tone": tone, "caliber": caliber, 'unit': unit_id });
    }

    basketConfirm(descr = "") {
        return this.exec('basket_confirm', { "descr": descr });
    }
    /**
     * Обновление количество в корзине 
     * @param {*} id  - идентификатор корзины 
     * @param {*} qty - количество 
     * @returns 
     */
    basketUpdateQty(id, qty) {
        return this.exec('update_qty', { 'basket_id': id, 'qty': qty });
    }

    /**
     * Удаление из корзины  
     * @param {*} id  - идентификатор позиции в корзине 
     */
    removeFromBasket(id) {
        return this.exec('remove_from_basket', { "id": id })
    }
    /**
     * Отправка на почту кода  
     * @param {*} type       
     * @returns json    
     */
    sendLinkEmail(type) {
        console.log(type);
        return this.exec('send_link_email', { 'type': type });
    }
    changeRegions(name_reg) {
        return this.exec('change_region', { 'name_reg': name_reg });
    }
    /**
     * Получение html покупки номенклатуры 
     * @param {*} id      
     * @returns html  
     */
    getItemBasketPopup(id) {
        return this.exec('get_popup_by_item', { 'id': id })
    }
    generatePrice() {
        const responseType = 'blob';
        return this.exec('generate_price', {}, responseType);
    }
    getItems(text) {
        return this.exec('get_items', { "search": text });
    }
    /**
     * 
     * @param {*} lookup_action 
     * @param {*} page 
     * @returns 
     */
    lookUp(lookup_action, control_name,  page=1) {
        return  this.exec(lookup_action, {'control': control_name, "page": page}, 'html');
    }
    getAxclassif( id ) {
        return  this.exec('get_ax_classif', {'id': id}, 'html');
    }
    getCategoyMenuList(menu_id, url){
        return  this.exec('get_categoy_menu_list', {'menu_id': menu_id, 'url': url});
    }
    /**
     * обновление строки карзины
     */
    refreshBasketLine(baskeId, unitId, qty) {
        return  this.exec('refresh_line'
        , { "basket_id": baskeId, "unit_id": unitId, 'qty': qty});
    }  
    getRegions() {            
        return this.exec('get_regions',{});
    }    
    setRegion(locationId, locationType) {            
        return this.exec('set_region', {"location_id": locationId, 'location_type': locationType});
    }  
}
